import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`19th February 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A user now can see the content of HTTP request/response in logs. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1574"
          }}>{`#1574`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
// Enables previewing the content with the maximum length of 100 for textual contents.
sb.contentPreview(100);

// A user can use their customized previewer factory.
sb.contentPreviewerFactory((ctx, headers) -> {
    return ContentPreviewer.ofBinary(100, byteBuf -> {
        byte[] contents = new byte[Math.min(byteBuf.readableBytes(), 100)];
        byteBuf.readBytes(contents);
        return BaseEncoding.base16().encode(contents);
    });
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`ClientRequestContextBuilder`}</inlineCode>{` and `}<inlineCode parentName="p">{`ServiceRequestContextBuilder`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1548"
          }}>{`#1548`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`A user can create a mock of `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`A user can emulate an incoming request and feed it into his or her processing pipeline.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Test
public void testService() throws Exception {
    // Given
    HttpRequest req = HttpRequest.of(HttpMethod.POST, "/greet",
                                     MediaType.JSON_UTF_8,
                                     "{ \\"name\\": \\"foo\\" }");
    ServiceRequestContext sctx = ServiceRequestContext.of(req);

    // When
    HttpResponse res = service.serve(sctx, req);

    // Then
    AggregatedHttpMessage aggregatedRes = res.aggregate().get();
    assertEquals(200, aggregatedRes.status().code());
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A user can use `}<inlineCode parentName="p">{`@CorsDecorator`}</inlineCode>{` in annotated services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1547"
          }}>{`#1547`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`sb.annotatedService("/cors6", new Object() {

    @Get("/any/get")
    @CorsDecorator(origins = "*", exposedHeaders = { "expose_header_1", "expose_header_2" },
        allowedRequestHeaders = { "allow_request_1", "allow_request_2" },
        allowedRequestMethods = HttpMethod.GET, maxAge = 3600,
        preflightRequestHeaders = {
            @AdditionalHeader(name = "x-preflight-cors", value = "Hello CORS")
        })
    public HttpResponse anyoneGet() {
        return HttpResponse.of(HttpStatus.OK);
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`ClientFactoryBuilder.domainNameResolverCustomizer()`}</inlineCode>{` so that a user can customize the resolver easily. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1553"
          }}>{`#1553`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory f = new ClientFactoryBuilder()
    .domainNameResolverCustomizer(resolverBuilder -> {
        resolverBuilder.maxQueriesPerResolve(10);
        resolverBuilder.traceEnabled(false);
    })
    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A user can define a custom annotation which attaches other annotations for simplicity. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1560"
          }}>{`#1560`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Define a custom annotation:
@ProducesJson
@LoggingDecorator
@interface MyApiSpecification {}

// Apply it to the annotated HTTP service:
@Get("/api")
@MyApiSpecification // You can use one annotation which holds other other annotations.
public Something getSomething() {}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`@AdditionalHeader`}</inlineCode>{` and `}<inlineCode parentName="p">{`@AdditionalTrailer`}</inlineCode>{` to insert headers easily in annotated services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1555"
          }}>{`#1555`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added a way to add multiple gRPC services with a single method call. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1563"
          }}>{`#1563`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before
GrpcService s = new GrpcServiceBuilder().addService(a)
                                        .addService(b)
                                        .build();
// After
GrpcService s = new GrpcServiceBuilder().addServices(a, b).build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added more shortcut methods for convenience. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1576"
          }}>{`#1576`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest req = ...;
AggregatedHttpMessage aggregated = ...;
MediaType contentType;
String content;

// Before
contentType = req.headers().contentType();
contentType = aggregated.headers().contentType();
content = aggregated.content().toStringUtf8();

// After
contentType = req.contentType();
contentType = aggregated.contentType();
content = aggregated.contentUtf8();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`RequestObject`}</inlineCode>{` is shown in `}<inlineCode parentName="p">{`DocService`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1557"
          }}>{`#1557`}</a>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`verboseSocketExceptions`}</inlineCode>{` flag so that a user can ignore harmless socket-related error message. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1577"
          }}>{`#1577`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added automatic directory listing generation to `}<inlineCode parentName="p">{`HttpFileService`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1573"
          }}>{`#1573`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`armeria-spring-boot-actuator`}</inlineCode>{` dependency. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1578"
          }}>{`#1578`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Works without Spring Web or Webflux.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added metrics related to timeouts. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1589"
          }}>{`#1589`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<inlineCode parentName="p">{`responseCauseSanitizer`}</inlineCode>{` to `}<inlineCode parentName="p">{`LoggingDecoratorBuilder`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1594"
          }}>{`#1594`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`A user can sanitize the stack trace of `}<inlineCode parentName="li">{`RequestLog.responseCause()`}</inlineCode>{` or avoid logging the stack trace completely.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
final Function<Throwable, Throwable> responseCauseSanitizer = cause -> {
    if (cause instanceof AnticipatedException) {
        return null; // Do not log when AnticipatedException is raised. 
    }
    return cause;
 };

 sb.decorator(new LoggingServiceBuilder().requestLogLevel(LogLevel.INFO)                                                    
                                         .successfulResponseLogLevel(LogLevel.INFO)                                                    
                                         .responseCauseSanitizer(responseCauseSanitizer)
                                         .newDecorator());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A user can easily send `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/2009/WD-eventsource-20090421/"
          }}>{`Server-Sent Events`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1551"
          }}>{`#1551`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
    @Get("/publisher")
    @ProducesEventStream
    public Publisher<ServerSentEvent<?>> publisher() {
        return Flux.just(ServerSentEvent.ofData("foo"),
                         ServerSentEvent.ofData("bar"),
                         ServerSentEvent.ofData("baz"),
                         ServerSentEvent.ofData("qux"));
    }
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`User-friendly message when `}<inlineCode parentName="li">{`400 Bad Request`}</inlineCode>{` happens. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1575"
        }}>{`#1575`}</a></li>
      <li parentName="ul">{`Enabled DNS query traces by default. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1553"
        }}>{`#1553`}</a></li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`armeria-retry-count`}</inlineCode>{` header when `}<inlineCode parentName="li">{`RetryingClient`}</inlineCode>{` is used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1593"
        }}>{`#1593`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a bug where `}<inlineCode parentName="li">{`httpStatus`}</inlineCode>{` tag is not set properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1559"
        }}>{`#1559`}</a></li>
      <li parentName="ul">{`Do not set `}<inlineCode parentName="li">{`Content-length`}</inlineCode>{` header when HTTP trailers exist. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1566"
        }}>{`#1566`}</a></li>
      <li parentName="ul">{`Fixed a bug where max request length is not set correctly in `}<inlineCode parentName="li">{`GrpcService`}</inlineCode>{` when the value is `}<inlineCode parentName="li">{`0`}</inlineCode>{` or `}<inlineCode parentName="li">{`Long.MAX_VALUE`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1549"
        }}>{`#1549`}</a></li>
      <li parentName="ul">{`Fixed a but where gRPC JSON marshaller is initialized even when unnecessary. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1558"
        }}>{`#1558`}</a></li>
      <li parentName="ul">{`Fixed a bug where gRPC callbacks are not executed in order. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1600"
        }}>{`#1600`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-change",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-change",
        "aria-label": "breaking change permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking Change`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{` in `}<inlineCode parentName="li">{`AggregatedHttpMessage`}</inlineCode>{` is immutable.`}
        <ul parentName="li">
          <li parentName="ul">{`You should call `}<inlineCode parentName="li">{`headers.toMutable()`}</inlineCode>{` to set or remove a header. `}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext.isTimedOut()`}</inlineCode>{` has been deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1589"
        }}>{`#1589`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`ServiceRequestContext.isTimedOut()`}</inlineCode>{` instead.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bouncy Castle 1.60 -> 1.61`}</li>
      <li parentName="ul">{`Brave 5.6.0 -> 5.6.1`}</li>
      <li parentName="ul">{`java-jwt 3.5.0 -> 3.7.0`}</li>
      <li parentName="ul">{`Micrometer 1.1.2 -> 1.1.3`}</li>
      <li parentName="ul">{`Netty 4.1.32 -> 4.1.33`}</li>
      <li parentName="ul">{`Project Reactor 3.2.5 -> 3.2.6`}</li>
      <li parentName="ul">{`protobuf-jackson 0.3.0 -> 0.3.1`}</li>
      <li parentName="ul">{`RxJava 2.2.5 -> 2.2.6`}</li>
      <li parentName="ul">{`Thrift 0.11.0 -> 0.12.0`}</li>
      <li parentName="ul">{`Tomcat 9.0.14 -> 9.0.16, 8.5.37 -> 8.5.38`}</li>
      <li parentName="ul">{`spring-boot-starter-actuator has been removed from the transitive dependencies.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      